import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = ({ children, pageContext, location }) => {
  const { title } = pageContext.frontmatter;
  return (
    <Layout>
      <Seo title={title} path={location.pathname} />
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                {title}
              </h1>
            </div>
          </div>

          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            {children}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/page.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>


    <p>{`We put security first, and we strive to implement the best security procedures and practices appropriate to the nature of the information we store, in order to protect it from unauthorized access, destruction, use, modification, or disclosure.`}</p>
    <h3>{`Infrastructure`}</h3>
    <p>{`Tresorbase is hosted on Google Cloud Platform and AWS. Google and AWS data centers feature extensive safeguards and security layers. You can find more here:`}</p>
    <ul>
      <li parentName="ul">{`Google`}</li>
      <li parentName="ul">{`AWS`}</li>
    </ul>
    <h3>{`High Availability`}</h3>
    <p>{`Every part of Tresorbase is provisioned to be highly available. We have redundant system in the case of failure.`}</p>
    <h3>{`Data Location`}</h3>
    <p>{`We use a mix of data centers all located in Europe.`}</p>
    <h3>{`Disaster Recovery`}</h3>
    <p>{`Daily backups are generated and saved across multiple regions. In the event of a region-wide outage, we are able to bring up our system in a different region.`}</p>
    <h3>{`Data transmission`}</h3>
    <p>{`Data is sent securely to Tresorbase via TLS to an HTTPS endpoint. All data is AES-256bit encrypted, both in transit and at rest.`}</p>
    <h3>{`Data Encryption`}</h3>
    <p>{`All data in Tresorbase servers is encrypted at rest. GCP and AWS store and manage data cryptography keys. So, if an intruder were ever able to access any of the physical storage devices, the Tresorbase data contained therein would still be impossible to decrypt without the keys.`}</p>
    <h3>{`Data Removal`}</h3>
    <p>{`All customer data stored on Tresorbase servers is deleted upon a customer’s termination of service and deletion of account in no longer than 30 days.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      